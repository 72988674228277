import { SalesOrderStatus } from "api"
import { AdminRoles } from "api/generated/models/AdminRoles"
import { Status } from "common/components"
import { Statuses } from "common/statuses"
import { ValidationErrors } from "common/utils/validation"
import { CommandId } from "features/commands/useCommand/commands"

const common = {
  actions: "{{item}} actions",
  addItem: "Add {{name, lowercase }}",
  addItemSuccess: "{{name}} added",
  addNew: "Add new {{ item, lowercase }}",
  address: "Address",
  addressLine: "Address line {{ number }}",
  admin: "Admin",
  admin_other: "Admins",
  adminName: "$t(admin) $t(name)",
  adminRole: "$t(admin) $t(role)",
  appName: "IAM Portal",
  back: "Back",
  backTo: "Back to {{ location }}",
  cancel: "Cancel",
  catalogue: "Catalogue",
  category: "Category",
  category_other: "Categories",
  cdm: "Cloud Drive Mapper",
  city: "City",
  clear: "Clear",
  close: "Close",
  command: "Command",
  companyName: "IAM Technology Group",
  conditions: "Conditions",
  conductor: "Conductor",
  confirm: "Confirm",
  confirm_header: "Confirm deletion",
  consumer: "Consumer",
  consumer_other: "Consumers",
  contactSupport: "contact support",
  country: "Country",
  create: "Create",
  currency: "Currency",
  customer: "Customer",
  customer_other: "Customers",
  dashboard: "Dashboard",
  datacentre: "Data centre",
  delete: "Delete",
  deliveryDate: "Delivery Date",
  description: "Description",
  details: "Details",
  discard: "Discard",
  discardChanges: "Discard changes",
  discount: "Discount",
  distributor: "Distributor",
  distributor_other: "Distributor",
  domain: "Domain",
  domain_other: "Domains",
  done: "Done",
  edit: "Edit",
  editItem: "Edit {{name}}",
  email: "Email",
  emailAddress: "$t(email) $t(address)",
  entity: "Entity",
  entity_other: "Entities",
  favourites: "Favourites",
  findOutMore: "Find out more",
  go: "Go",
  goTo: "Go to {{location}}",
  identifier: "Identifier",
  identifier_other: "Identifiers",
  idx: "Identity Exchange",
  ifProblemPersistsContact: "If the problem persists please contact",
  image: "Image",
  image_other: "Images",
  inShop: "InShop",
  integration: "Integration",
  inventory_title: "Inventory",
  invitation: "Invitation status: {{status, lower}}",
  irisMenu: "Iris Menu",
  liftnshift: "Lift&Shift",
  lobby: "Lobby",
  makeDefault: "Make default",
  managing: "Managing",
  migration: "Migration",
  migration_other: "Migrations",
  mobile: "Mobile",
  name: "Name",
  no: "No",
  noResults: "No results found",
  note: "Note",
  notifications: "Notifications",
  or: "or",
  orders: "Orders",
  parent: "Parent",
  partner: "Partner",
  partner_other: "Partners",
  phone: "Phone",
  placeholderNetflix: "eg. Netflix",
  post_code: "Post code",
  price: "Price",
  product_categories: "Product categories",
  productTitle: "Product",
  profile: "Profile",
  purchase: "Purchase",
  region_label: "Region",
  removeItem: "Remove {{name}}",
  role: "Role",
  root: "Root",
  root_other: "Roots",
  sale_other: "Sales",
  save: "Save",
  saveChanges: "Save changes",
  search: "Search",
  searchPlaceholder: "Search...",
  searchProduct: "Search product",
  select: "Select",
  settings_string: "Settings",
  signin: "Sign in",
  signout: "Sign out",
  somethingWentWrong: "Something went wrong",
  sso: "Simple Sign-On",
  status_label: "Status",
  storeFront: "Store front",
  storeFront_other: "Store fronts",
  surpass: "Surp4ss!",
  synchronize: "Synchronize",
  tenancy: "Tenancy",
  tenancy_other: "Tenancies",
  tenancyManagement: "Tenancy management",
  timeZone: "Time zone",
  tryAgain: "Try again",
  type: "Type",
  unableTo: "Unable to {{action, lowercase}}",
  usersnroles: "Users&roles",
  variant_option: "Variant option",
  variant_option_other: "Variant options",
  website: "Website",
  yes: "Yes",
}

const inShop = {
  addCustomer: "Add customer",
  amount: "Amount",
  amountShouldBeGreatThanTotal: "The amount must be greater or equal to the total",
  cancelOrder: "Cancel order",
  card: "Card",
  cash: "Cash",
  changeCustomer: "Change customer",
  changePaymentMethod: "Change \n payment \n method",
  changeStorefront: "Change storefront",
  deposit: "Deposit",
  due: "Due",
  exact: "Exact",
  iva: "IVA",
  newCustomer: "New customer",
  newOrder: "New order",
  noReceipt: "No \n receipt",
  or: "Or",
  orderSummary: "Order summary",
  payment: "Payment",
  paymentAccepted: "Payment \n accepted",
  paymentDeclined: "Payment \n declined",
  pressToStart: "Press to start an order",
  price: "Price",
  printReceipt: "Print \n receipt",
  processPayment: "Process payment",
  quantity: "Quantity",
  remaining: "Remaining",
  scanToStart: "Scan an item to start an order",
  selectStorefront: "Please select a storefront",
  subtotal: "Subtotal",
  total: "Total",
}

const commands: { [key in CommandId]: string } = {
  "auth > signin": common.signin,
  "auth > signout": common.signout,
  "clipboard > copyToken": "Auth: Copy auth token to clipboard",
  "clipboard > copyUser": "Auth: Copy user as JSON to clipboard ",
  "customer > manage > disable": "Customers: Disable manage customer mode",
  "customer > manage > enable": "Customers: Manage customer...",
  "customer > manage > selectCustomer": "{{label}}",
  "route > goTo": "Go to route: {{label}}",
}

const components = {
  deleting_modal: "Do you want to delete {{name}} {{type}}?",
  downloadAppPage: {
    haveYouDownloadedApp: "Have you downloaded the app?",
    message: "Hey there! It looks like you're trying to access inShop through a web browser.",
  },
  footer: {
    copyright: `© IAM Technology Group Ltd {{year}}. All rights reserved.`,
  },
  imagesControl: {
    chooseFiles: "Choose files",
    choosePhoto: "Choose a photo",
    dragAndDropAnImageToUpload: "Drag & Drop an image to upload",
    images: "Images",
    makeTheMainImage: "Make the main image",
    or: "or",
    photoLibrary: "Photo library",
    uploadPhotos: "Upload photos",
  },
  input: {
    placeholder: "Please enter a search term",
  },
  notifications: {
    clearAll: "clear all",
    notifications: "Notifications",
    weLetYouKnowWhenYouDo: "but we’ll let you know when you do",
    youDontHaveNotifications: "You don’t have any notifications",
  },
  outOfStockButtonNo: "No, thanks",
  outOfStockButtonYes: "Yes, please",
  outOfStockModalContent: "Would you like to go out to the product variant page to restock?",
  outOfStockModalTitle: "The item is out of stock",
  pagination: {
    itemsPerPage: "Items per page",
    next: "Next page",
    ofTotal: "of {{count}} page",
    ofTotal_other: "of {{count}} pages",
    page: "Page",
    previous: "Previous page",
  },
  profileMenu: {
    legalSettings: "Legal Settings",
    myProfile: "My Profile",
    organisationSettings: "Organisation Settings",
    signOut: "Sign Out",
  },
  search: {
    placeholder: "Search for {{ item }}",
  },
  textarea: {
    placeholder: "Write a {{item, lowercase}}",
    placeholderFormNote: "Leave a note...",
  },
  topBar: {
    commandInput: {
      placeholder: `Search for a ${common.command.toLocaleLowerCase()}`,
    },
    tenancyInput: {
      placeholder: `Search for a ${common.tenancy.toLocaleLowerCase()}`,
    },
  },
}

const scannedBarcode = {
  addAsProduct: "Add it as a new product",
  addAsVariant: "Add it as a variant",
  message: "Scanned barcode cannot be found in the system.",
}

const settings = {
  companyInfo: {
    companyInformation: "Company information",
    companyName: "Company name",
    industry: "Industry",
    taxLabel: "Tax label",
    taxNumber: "Tax number",
  },
  configSearch: {
    checkbox: "Checkbox",
    go: common.go,
    placeholder: "Enter a search term",
    search: common.search,
  },
  configurations: "Configurations",
  currencies: {
    addNewCurrency: "Add new currency",
    currency: common.currency,
    currency_other: "Currencies",
    currencyChanges: {
      addANewCurrency: "Add a new currency",
      currency: common.currency,
      editCurrency: common.editItem,
      exchangeRate: "Exchange rate",
      makeDefaultCurrency: "Make the default currency of the system",
      modal: {
        no: "No, keep the current currency",
        question: "You made {{name}} your default currency. Are you sure?",
        yes: "Yes, change the default currency",
      },
      select: common.select,
      tooltipLabel:
        "ISA uses live Market exchange that updates every 1 hour. You can override the exchange rate manually.",
    },
    default: "Default",
    defaultCurrency: "Default currency",
    delete: common.delete,
    edit: common.edit,
    makeDefault: common.makeDefault,
    rate: "Rate",
    symbol: "Symbol",
  },
  languages: {
    default: "Default",
    defaultLanguage: "Default language",
    edit: common.edit,
    enabled: "Enabled",
    language: "Language",
    languageChanges: {
      addNewLanguage: "Add a new language",
      editItem: common.editItem,
      enableLanguage: "Enable language",
      makeDefaultLanguage: "Make the default language of the system",
      makeSystemLanguage: "Make the system language",
      modal: {
        default: {
          no: "No, keep the current default language",
          question: "You have made {{name}} your default language. This will change InShop to {{name}}",
          yes: "Yes, change the default language",
        },
        system: {
          no: "No, keep the current system language",
          question: "You have made {{name}} your system language. This will change the system to {{name}}",
          yes: "Yes, change the system language",
        },
      },
    },
    makeDefault: common.makeDefault,
    systemLanguage: "System language",
  },
  storeFronts: {
    addNewStoreFront: "Add new store front",
    currency: "Currency",
    defaultProducts: "Add products to the store by default",
    description: "Store description",
    fullSync: "Full sync",
    generateKey: "Generate key",
    hydrateStoreFront: "Hydrate store front",
    language: "Language",
    maintainStockLevels: "Maintain stock levels",
    physical: "Physical",
    placeholder: {
      description: "Enter description",
      name: common.placeholderNetflix,
      webAddress: "http://www.company.com",
    },
    preClearDown: "PreClearDown",
    preClearDownConfirm: "This action will remove all variants from WooCommerce. Are you sure?",
    storeFront: common.storeFront,
    storeFront_other: "Store fronts",
    type: "Store type",
    virtual: "Virtual",
    webAddress: "Web address",
    websiteType: "Website type",
    websiteValidation: "Should be subdomain + name + extension",
  },
  troubleshooting: {
    clear: common.clear,
    enableRealtimeLogging: "Enable realtime logging",
    send: "Send",
    troubleshooting_title: "Troubleshooting",
  },
}

const features = {
  admin: {
    configuringTenancies: "Configuring tenancies",
    noLinkedTenancies: "You do not appear to have any linked tenancies",
    problemGettingDetails: "There was a problem getting your details",
    problemLoggingIn: "There was a problem logging you in",
    settingUpYourProfile: "Setting up your profile",
  },
  commands,
  customers: {
    addCustomer: "Add customer",
    customerType: {
      all: "All customers",
      consumer: common.consumer,
      consumer_lower: common.consumer.toLowerCase(),
      consumer_other: common.consumer_other,
      consumer_other_lower: common.consumer_other.toLowerCase(),
      customer: common.customer,
      customer_lower: common.customer.toLowerCase(),
      customer_other: common.customer_other,
      customer_other_lower: common.customer_other.toLowerCase(),
      distributor: common.distributor,
      distributor_lower: common.distributor.toLowerCase(),
      distributor_other: common.distributor_other,
      distributor_other_lower: common.distributor_other.toLowerCase(),
      partner: common.partner,
      partner_lower: common.partner.toLowerCase(),
      partner_other: common.partner_other,
      partner_other_lower: common.partner_other.toLowerCase(),
      root: common.root,
      root_lower: common.root.toLowerCase(),
      root_other: common.root_other,
      root_other_lower: common.root_other.toLowerCase(),
    },
    disableManagedEntity: "Disable manage customer mode",
    noAdmins: "Please <strong>add at least one admin</strong><br/> before creating the new {{customerType}}",
    search: {
      button: "Search {{customerType}}",
      placeholder: "Find {{customerType}} by name",
    },
  },
  landing: {
    signIn: {
      button: "Sign in",
      instruction: "Please sign in before accessing this page.",
    },
  },
}

const products = {
  cdm: {
    description: "The world's leading drive mapper for Office 365",
    title: common.cdm,
  },
  conductor: {
    description: "Our supercharged automation platform",
    title: common.conductor,
  },
  idx: {
    description: "Connect and sync identities across systems",
    title: common.idx,
  },
  liftnshift: {
    description: "Taking the strain out of Office 365 migrations",
    title: common.liftnshift,
  },
  sso: {
    description: "Our simple and secure sign-on platform",
    title: common.sso,
  },
  surpass: {
    description: "Browser-based password reset for Active Directory",
    title: common.surpass,
  },
}

const sales = {
  addANewCustomer: "Add a new customer",
  addCustomer: "Add new customer",
  address: common.address,
  addressLine: common.addressLine,
  applyResellerTax: "Apply reseller tax",
  business: "Business",
  cancel: common.cancel,
  city: "City",
  company_name: common.companyName,
  companyName_placeholder: "eg. Netflix LTD",
  companyRegistrationNumber: "Company registration number",
  country: common.country,
  county: "County",
  credit: "Credit note",
  customer: "Customer",
  customer_other: "Customers",
  customerType: {
    all: "All customers",
    consumer: "Consumer",
    consumer_other: "Consumers",
    customer: common.customer,
    customer_other: common.customer_other,
    distributor: "Distributor",
    distributor_other: "Distributors",
    partner: "Partner",
    partner_other: "Partners",
    root: "Root",
    root_other: "Roots",
  },
  customerTypeLabel: "Customer type",
  default_product_category: "Default product category",
  default_product_country_of_origin: "Default product country of origin",
  deliveryAddress: "Delivery address",
  deliveryDate: "Delivery date",
  disableManagedEntity: "Disable manage customer mode",
  editCustomer: "Edit customer",
  email: common.email,
  general: "General",
  individual: "Individual",
  invoice: "Invoice",
  mobile: "Mobile",
  mobileNumber: "Mobile number",
  next: "Next",
  nie: "NIE",
  nifOrCif: "NIF/CIF",
  notes: "Notes",
  orderDate: "Order date",
  orderNumber: "Order number",
  orderStatus: "Order status",
  orderValue: "Order value",
  paidAmount: "Paid amount",
  payRemainder: "Pay remainder",
  payment: "Payment",
  paymentMethod: "Payment method",
  pendingOrders: "Pending orders",
  phone: "Phone",
  placeholder: {
    country: "Select country",
    name: common.placeholderNetflix,
  },
  postCode: "Post code",
  price_includes_tax: "Price includes sales tax",
  printReceipt: "Print receipt",
  quantity: "Quantity",
  refundOrExchange: "Refund or Exchange",
  region: "Region",
  remainingAmount: "Remaining amount",
  sales: "Sales",
  salesOrders: {
    addNewOrder: "Add new order",
    salesOrdersTitle: "Sales orders",
  },
  search: {
    button: "Search {{customerType}}",
    placeholder: "Find {{customerType}} by name",
    searchEmailOrMobileNumber: "Search email or mobile number",
    searchExistingCustomer: "Search an existing customer",
  },
  shipments: "Shipment methods",
  state: "State",
  status: common.status_label,
  storeFront: "Store front",
  tax_registration_number: "Tax registration number",
  totalPaid: "Total paid",
  totalRemaining: "Total remaining",
  townOrCity: "Town/City",
  vatRegistrationNumber: "VAT registration number",
  writeAddress: "Write address",
  writeNotes: "Write notes",
  zipCode: "Zip code",
}

const purchases = {
  addNewSupplier: "Add new supplier",
  company_name: common.companyName,
  default_product_category: "Default product category",
  pending_orders: "Pending orders",
  purchase: "Purchases",
  purchase_order: "Purchase order",
  supplier: "Supplier",
  supplier_credit: "supplier_credit",
  suppliers_other: "Suppliers",
}

const inventory = {
  addNewCategory: "Add new category",
  category_type: "Category type",
  description: common.description,
  description_placeholder: "Write description",
  inventory: "Inventory",
  main_category: "Main category",
  name: common.name,
  product: {
    add: common.addItem,
    addMore: "Add more",
    addNewProduct: "Add new product",
    addStoreFront: "Add a store front",
    applyResellerTax: "Apply reseller tax",
    associatedProducts: {
      associateProducts: "Associate products",
      clickOnAssociatedProductToDelete: "Click on the associated product you wish to delete",
      crossLinkProducts: "Cross link products",
      deleted: "Deleted",
      doubleClickOnProductOrVariantToBeAdded: "Double click on the product or the product variant to be added",
      editAssociatedProducts: "Edit associated products",
      editCrossLinkedProducts: "Edit cross linked products",
      searchProductOrVariant: "Search for a product or product variant",
      searchProductToAssociate: "Search or scan a product to associate it",
      searchProductToCrossLink: "Search or scan a product to cross link it",
    },
    attributes: "Attributes",
    availableStockPerStoreFront: "Available stock per store front",
    back: common.back,
    carryingCost: "Carrying cost",
    category: "Category",
    confirm: "Confirm",
    countryOfOrigin: "Country of origin",
    delete: common.delete,
    description: common.description,
    differentPricePerProductVariant: "Different price per product variant",
    edit: common.editItem,
    editProduct: "Edit {{name}} product",
    editVariant: "Edit variant",
    enterBarcodeManually: "Enter the barcode Manually",
    enterManually: "Enter manually",
    hsCode: "HS code",
    image: "Image",
    initialCostOfGoods: "Initial cost of goods",
    initialStock: "Initial stock",
    initialStockPerVariant: "Initial stock per variant",
    inventory: "Inventory",
    inventoryType: "Inventory type",
    makeDefault: common.makeDefault,
    makeItFavorite: "Make it favorite",
    name: common.name,
    newVariants: "New variants",
    next: "Next",
    notes: "Notes",
    placeholder: {
      common: "Placeholder",
      customHSCode: "Custom HS code",
      name: common.placeholderNetflix,
      select: common.select,
      writeDescription: "Write a description",
      writeShortName: "Write a short name",
    },
    price: "Price",
    product: "Product",
    product_low: "product",
    product_other: "Products",
    productCategory: "Product category",
    productImages: "Product images",
    productInfo: "Product info",
    productName: "Product name",
    productVariantImages: "Product variant images",
    receiptDisplayName: "Receipt display name",
    resellerTaxCategory: "Reseller tax category",
    retailPricePerStoreFront: "Retail price per store front",
    reviewProductVariants: "Review product variants",
    reviewVariant: "Review variant",
    reviewVariant_other: "Review variants",
    rrp: "RRP",
    rrpIncludesTax: "RRP includes tax",
    salesTaxCategory: "Sales tax category",
    saveNnext: "Save & next",
    scanBarcode: "Scan barcode",
    select: common.select,
    selectStore: "Select store",
    shortName: "Short name",
    sku: "SKU",
    status: common.status_label,
    statusActive: "Active",
    statusInactive: "Inactive",
    stockLevel: "Stock level",
    stockPerStoreFront: "Stock per store front",
    storeFront: common.storeFront,
    supplier: "Supplier",
    supplierBarcode: "Supplier barcode",
    supplierSKU: "Supplier SKU",
    tags: "Tags",
    taxInfo: "Tax info",
    totalProductVariants: "Total product variants",
    totalStock: "Total stock",
    unitCostPerVariant: "Unit cost per variant",
    unitOfMeasure: "Unit of measure",
    unitRRPPerVariant: "Unit RRP per variant",
    variant: "Variant",
    variantNumberOfAllVariants: "Variant {{variantNumber}} of {{allVariants}}",
    variants: "Variants",
    variantsAndStoreFronts: "Variants and store fronts",
    variantsNumber: "{{count}} variants",
    writeDownTheVariant: "Write down the variant",
  },
  product_category: {
    no_of_subcategories: "No. of sub-categories",
  },
  subcategory: "Subcategory",
  tax_category: "Tax category",
  unitOfMeasure: {
    addNewUnit: "Add new unit",
    addNewUnitOfMeasure: "Add New unit of measure",
    shortName: "Short name",
    unitOfMeasure: "Unit of measure",
    unitOfMeasures: "Unit of measures",
  },
  variant_option: {
    add_new_option: "Add new variant option",
    add_new_option_value: "Add new option value",
    add_new_value: "Add new value",
    edit_value: "Edit {{name}} value",
    image_or_photo: "Value image/photo",
    no_of_option_values: "No. of option values",
    option_colour: "Option colour",
    option_image: "Option image",
    option_name: "Option name",
    option_value: "option value",
    option_values: "Variant option values",
    placeholder: {
      option_name: "eg. gender, taste",
      option_values: "Write a value and press enter",
    },
    value: "Value",
    value_option: "Value option",
    variant_option: "Variant option",
    variant_option_other: common.variant_option_other,
    variantValue: "Variant value",
  },
}

export const placeholder = {
  email: "name@domain.com",
  name: "Firstname Lastname",
}

const validation: { [key in ValidationErrors]: string } = {
  email: "Please enter a valid email address.",
  length: "Please enter {{min}} – {{max}} characters.",
  letters: "Please enter only letters",
  lettersNnumbers: "Please enter only letters and numbers",
  lettersNnumbersNspecialCharacters: "Please use only letters, numbers and special characters",
  lettersNnumnersNdash: "Please use only letters, numbers and dash",
  lettersNnumnersNdot: "Please use only letters, numbers and dot",
  maxLength: "Please enter {{length}} characters or fewer.",
  minLength: "Please enter {{length}} characters or more.",
  numbers: "Please use numbers",
  phone: "Please enter a valid number",
  required: "Please enter a {{field, lowercase}}.",
  select: "Please select a {{field, lowercase}}",
}

const error = {
  auth: {
    heading: "Hold up!",
    message: "Please log in and try again.",
  },
  generic: {
    heading: "Oopsiedoodles!",
    message: "Please don't cry. Press F5 and let's retry!",
  },
  pageNotFound: {
    heading: "Roses are red, circles are round",
    link: "Take me home!",
    message: "The page you're looking for cannot be found.",
  },
  unauthorised: {
    heading: "Sorry, you don't have access to this page.",
    message: "Please contact your account manager.",
  },
}

const statusIcon: { [key in Status]: string } = {
  Accepted: "Accepted",
  Error: "Error",
  Pending: "Pending",
  Rejected: "Rejected",
}

const statuses: { [key in Statuses]: string } = {
  Active: "Active",
  Inactive: "Inactive",
}

const salesOrderStatus: { [key in SalesOrderStatus]: string } = {
  Canceled: "Canceled",
  Paid: "Paid",
  Pending: "Pending",
}

const roles: { [key in AdminRoles]: string } = {
  CDMAdministrator: "CDM admin",
  CustomerAdministrator: "Customer admin",
  DistributorAdministrator: "Distributor admin",
  Finance: "Finance",
  Helpdesk: "Helpdesk",
  IACAdministrator: "IAM Cloud admin",
  IDxAdministrator: "IDx admin",
  Legal: "Legal",
  MigrationAdministrator: "Migration admin",
  PartnerAdministrator: "Partner admin",
  PartnerManager: "Partner manager",
  TenancyAdministrator: "Tenancy admin",
}

const region = {
  eu: "EU",
  uk: "UK",
  us: "US",
}

export const en = {
  translation: {
    ...common,
    ...components,
    ...features,
    ...inventory,
    ...inShop,
    ...purchases,
    ...sales,
    ...settings,
    error,
    placeholder,
    products,
    region,
    roles,
    salesOrderStatus,
    scannedBarcode,
    statusIcon,
    statuses,
    validation,
  },
}
